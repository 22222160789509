/**
 *
 */
const fields = {
	startDate: null, // start date to filter on
	endDate: null, // end date to filter on
	products: [], // filtered products
	products_count: null, // filtered products count
	families: [], // filtered families
	families_count: null, // filtered families count
	stores: [], // filtered stores
	stores_count: null, // filtered stores count
	suppliers: [], //filtered suppliers
	suppliers_count: null, // filter supplier count
	warehouses: [], // filtered warehouses (not used yet)
};

export const filtersData = {
	namespaced: true,
	state: {
		// Filters data for Event page
		event: {
			...fields,
		},

		// Filters data for Event Creation page
		eventCreation: {
			...fields,
		},

		// Filters data for Campaign section of Launch
		launchCampaign: {
			...fields,
		},

		// Filters data for Products page of Launch
		launchProducts: {
			...fields,
		},

		// Filters data for Campaign page of Launch
		launchCampaignTable: {
			...fields,
		},

		// Filters data for Budget Assortment page of Launch
		launchBudgetAssort: {
			...fields,
		},

		// Filters data for Budget performance
		launchBudgetPerformance: {
			brands: [], // filtered brands
			brands_count: null, // filtered brands count
		},

		// Filters data for Budget estimating
		launchBudgetEstimate: {
			brands: [], // filtered brands
			brands_count: null, // filtered brands count
			categories: [], // filtered categories
			categories_count: null, // filtered categories count
			products: [], // filtered products
			products_count: null, // filtered products count
		},

		// Filters data for Products page of Price
		priceProducts: {
			...fields,
		},

		// Filters data for Campaign page of Price
		priceCampaign: {
			...fields,
		},

		// Filters data for Val Stock section of Operation
		operationValStock: {
			...fields,
		},

		// Filters data for Val Replenishment of Operation
		operationRepl: {
			...fields,
		},

		// Filters data for S&OP section of Operation
		operationSOP: {
			...fields,
		},
		// Filters data for Appro section of Operation
		operationAppro: {
			...fields,
		},

		// Filters data for PIC page of Operation
		operationPIC: {
			...fields,
		},

		// Filters data for PDP page of Operation
		operationPDP: {
			...fields,
		},

		// Filters data for DRP page of Operation
		operationDRP: {
			...fields,
		},

		// Filters data for LifeCycle page of Operation
		operationLifeCycle: {
			...fields,
		},

		// Filters data for supplier page of Operation
		operationSuppliers: {
			...fields,
		},

		// Filters data for Actions section of Pilotage
		pilotageActions: {
			...fields,
		},

		// Any unspecified store section, will default to this one
		default: {
			...fields,
		},
	},
	mutations: {
		// Sets the filters data for Meta/Event view.
		event(state, data) {
			state.event = { ...state.event, ...data };
		},

		// Sets the filters data for Meta/Event Creation view.
		eventCreation(state, data) {
			state.eventCreation = { ...state.eventCreation, ...data };
		},

		// Sets the filters data for Launch/Campaign view.
		launchCampaign(state, data) {
			state.launchCampaign = { ...state.launchCampaign, ...data };
		},

		// Sets the filters data for Launch/Campaign view.
		launchCampaignTable(state, data) {
			state.launchCampaignTable = {
				...state.launchCampaignTable,
				...data,
			};
		},

		// Sets the filters data for Launch/Products view.
		launchProducts(state, data) {
			state.launchProducts = { ...state.launchProducts, ...data };
		},

		// Sets the filters data for Launch/Budget Assortment view.
		launchBudgetAssort(state, data) {
			state.launchBudgetAssort = { ...state.launchBudgetAssort, ...data };
		},

		// Sets the filters data for Launch/Budget Performance view.
		launchBudgetPerformance(state, data) {
			state.launchBudgetPerformance = { ...state.launchBudgetPerformance, ...data };
		},

		// Sets the filters data for Launch/Budget Performance view.
		launchBudgetEstimate(state, data) {
			state.launchBudgetEstimate = { ...state.launchBudgetEstimate, ...data };
		},

		// Sets the filters data for Price/Products view.
		priceProducts(state, data) {
			state.priceProducts = { ...state.priceProducts, ...data };
		},

		// Sets the filters data for Price/Campaign view.
		priceCampaign(state, data) {
			state.priceCampaign = { ...state.priceCampaign, ...data };
		},

		// Sets the filters data for Val Stock section of Operation
		operationValStock(state, data) {
			state.operationValStock = { ...state.operationValStock, ...data };
		},

		// Sets the filters data for Replenishment section of Operation
		operationRepl(state, data) {
			state.operationRepl = { ...state.operationRepl, ...data };
		},

		// Sets the filters data for S&OP section of Operation
		operationSOP(state, data) {
			state.operationSOP = { ...state.operationSOP, ...data };
		},

		// Sets the filters data for S&OP section of Operation
		operationAppro(state, data) {
			state.operationAppro = { ...state.operationAppro, ...data };
		},

		// Sets the filters data for PIC of Operation
		operationPIC(state, data) {
			state.operationPIC = { ...state.operationPIC, ...data };
		},

		// Sets the filters data for PDP of Operation
		operationPDP(state, data) {
			state.operationPDP = { ...state.operationPDP, ...data };
		},

		// Sets the filters data for DRP of Operation
		operationDRP(state, data) {
			state.operationDRP = { ...state.operationDRP, ...data };
		},

		// Sets the filters data for Life Cycle of Operation
		operationLifeCycle(state, data) {
			state.operationLifeCycle = { ...state.operationLifeCycle, ...data };
		},

		// Sets the filters data for suppliers of operation
		operationSuppliers(state, data) {
			state.operationSuppliers = { ...state.operationSuppliers, ...data };
		},

		// Sets the filters data for Actions section of Pilotage
		pilotageActions(state, data) {
			state.pilotageActions = { ...state.pilotageActions, ...data };
		},

		// Sets the filters data for pages that don't have their sections yet (default).
		default(state, data) {
			state.default = { ...state.default, ...data };
		},
	},
	actions: {
		//
		setEventFilters({ commit }, data) {
			commit("event", data);
		},

		//
		setEventCreationFilters({ commit }, data) {
			commit("eventCreation", data);
		},

		//
		setLaunchCampaignFilters({ commit }, data) {
			commit("launchCampaign", data);
		},

		//
		setLaunchCampaignTableFilters({ commit }, data) {
			commit("launchCampaignTable", data);
		},

		//
		setLaunchProductsFilters({ commit }, data) {
			commit("launchProducts", data);
		},

		//
		setLaunchBudgetAssortFilters({ commit }, data) {
			commit("launchBudgetAssort", data);
		},

		//
		setLaunchBudgetPerformanceFilters({ commit }, data) {
			commit("launchBudgetPerformance", data);
		},

		//
		setLaunchBudgetEstimateFilters({ commit }, data) {
			commit("launchBudgetEstimate", data);
		},

		//
		setPriceProductsFilters({ commit }, data) {
			commit("priceProducts", data);
		},

		//
		setOperationValStockFilters({ commit }, data) {
			commit("operationValStock", data);
		},

		//
		setOperationReplFilters({ commit }, data) {
			commit("operationRepl", data);
		},

		//
		setOperationSOPFilters({ commit }, data) {
			commit("operationSOP", data);
		},
		//
		setOperationApproFilters({ commit }, data) {
			commit("operationAppro", data);
		},
		//
		setOperationPICFilters({ commit }, data) {
			commit("operationPIC", data);
		},

		//
		setOperationPDPFilters({ commit }, data) {
			commit("operationPDP", data);
		},

		//
		setOperationDRPFilters({ commit }, data) {
			commit("operationDRP", data);
		},

		//
		setOperationLifeCycleFilters({ commit }, data) {
			commit("operationLifeCycle", data);
		},

		//
		setOperationSuppliersFilters({ commit }, data) {
			commit("operationSuppliers", data)
		},

		//
		setPilotageActionsFilters({ commit }, data) {
			commit("pilotageActions", data);
		},

		//
		defaultAction({ commit }, data) {
			commit("default", data);
		},
	},
	getters: {
		// get Event filters data
		event: (state) => state.event,

		// get Event filters data
		eventCreation: (state) => state.eventCreation,

		// get Launch/Campaign filters data
		launchCampaign: (state) => state.launchCampaign,

		// get Launch/CampaignTable filters data
		launchCampaignTable: (state) => state.launchCampaignTable,

		// get Launch/Products filters data
		launchProducts: (state) => state.launchProducts,

		// get Launch/Products filters data
		launchBudgetAssort: (state) => state.launchBudgetAssort,

		// get Launch/budget performance filters data
		launchBudgetPerformance: (state) => state.launchBudgetPerformance,

		// get Launch/budget estimating filters data
		launchBudgetEstimate: (state) => state.launchBudgetEstimate,

		// get Price/Campaign filters data
		priceProducts: (state) => state.priceProducts,

		// get Price/Campaign filters data
		priceCampaign: (state) => state.priceCampaign,

		// get Val Stock section of Operation filters data
		operationValStock: (state) => state.operationValStock,

		// get Repl section of Operation filters data
		operationRepl: (state) => state.operationRepl,

		// get S&OP section of Operation filters data
		operationSOP: (state) => state.operationSOP,

		// get Appro section of Operation filters data
		operationAppro: (state) => state.operationAppro,


		// get PIC page of Operation filters data
		operationPIC: (state) => state.operationPIC,

		// get PIC page of Operation filters data
		operationPDP: (state) => state.operationPDP,

		// get DRP page of Operation filters data
		operationDRP: (state) => state.operationDRP,

		// get Life Cycle page of Operation filters data
		operationLifeCycle: (state) => state.operationLifeCycle,

		//get suppliers page of Operation filters data
		operationSuppliers: (state) => state.operationSuppliers,

		// get Actions section of Pilotage filters data
		pilotageActions: (state) => state.pilotageActions,

		// get default filters data
		default: (state) => state.default,
	},
};
