<template>
  <nav class="main-nav shadow-sm rounded px-4 py-3 pt-lg-3 fixed-top w-100">
    <!-- Navbar container -->
    <div class="d-flex flex-column flex-lg-row justify-content-between w-100">
      <!-- Logo -->
      <div class="d-flex justify-content-between">
        <a class="brand float-left" href="/">
          <img src="/img/brand/logo-fentech.png" class="logo" />
        </a>
        <b-button
          class="d-lg-none"
          variant="outline-secondary"
          @click="toggleNav = !toggleNav"
        >
          <i v-if="!toggleNav" class="fas fa-bars"></i>
          <i v-else class="fas fa-times"></i>
        </b-button>
      </div>
      <!-- Drop downs, settings and menu -->
      <div :class="`d-lg-flex ${toggleNav ? 'd-flex' : 'd-none'}`">
        <ul
          v-if="isLoggedIn || isAdminLoggedIn == 'true'"
          class="d-flex flex-column flex-lg-row pt-4 pt-lg-0 mb-0 w-100"
        >
          <!-- NAVIGATION BUTTONS -->
          <li>
            <div
              v-if="isSession"
              ref="headernav"
              :class="`d-flex flex-column flex-lg-row ${link_border} mr-2`"
            >
              <!-- -------------------------- -->
              <!-- Header drop down button    -->
              <!-- -------------------------- -->
              <DropDown
                v-if="
                  (user_role.includes('Pilotage') ||
                    user_role.includes('CompanyAdmin')) &&
                  hasAccess(company_name, 'Pilotage')
                "
                :title="$t('nav.pilotage.label')"
                icon="sort-amount-up-alt"
                :to="{ name: 'pilotage_sop_new' }"
                :links="[
                  {
                    label: $t('nav.ope.items[5]'),
                    to: '/pilotage/lifecycle',
                  },
                  { label: $t('nav.meta.items[1]'), to: '/pilotage/event' },
                  { label: 'Consensus', to: '/pilotage/consensus' },
                ]"
              />
              <DropDown
                v-if="
                  (user_role.includes('Operation') ||
                    user_role.includes('CompanyAdmin')) &&
                  hasAccess(company_name, 'Operation')
                "
                :title="$t('nav.ope.label')"
                icon="cogs"
                :to="{ name: 'operation_page' }"
                :links="getOperationSubPages(company_name)"
              />
              <DropDown
                v-if="
                  (user_role.includes('Prix') ||
                    user_role.includes('CompanyAdmin')) &&
                  hasAccess(company_name, 'Prix')
                "
                to=""
                :title="$t('nav.price.label')"
                icon="euro-sign"
                href="/price"
              />
              <DropDown
                v-if="
                  (user_role.includes('Lancement') ||
                    user_role.includes('CompanyAdmin')) &&
                  hasAccess(company_name, 'Lancement')
                "
                :title="$t('nav.launch.label')"
                icon="rocket"
                :to="{ name: 'launch_home' }"
                :links="[
                  //TODO:
                  //    - Delete Launch/Launch (page)
                  //    - Create Home button on drop down
                  //{ label: $t('nav.launch.items[0]'), to: '/launch/page' },
                  { label: $t('nav.launch.items[1]'), to: '/launch/product' },
                  {
                    label: $t('launch.campaign.simulation'),
                    to: '/launch/campaign',
                  },
                ]"
              />
              <DropDown
                v-if="hasAccess(company_name, 'Causality')"
                :title="$t('nav.causality.label')"
                icon="bar-chart"
                :to="{ name: 'causality' }"
                :links="[
                  {
                    label: $t('nav.causality.items[0]'),
                    to: '/causality',
                  },
                  {
                    label: $t('nav.causality.items[1]'),
                    to: '/causality/testing-analysis',
                  },
                ]"
              />
              <DropDown
                v-if="hasAccess(company_name, 'Data')"
                title="Data"
                icon="cube"
                :to="{ name: 'update_data_page' }"
              />
            </div>
          </li>
          <!-- Settings + Notifications + Menu -->
          <hr class="w-100" />
          <div
            class="d-flex align-items-center justify-content-center flex-grow-1 text-center"
          >
            <!-- Dashboard settings section  -->
            <!--
            <li class="flex-grow-1">
              <b-nav-item-dropdown
                variant="link"
                class="nav-item__hoverable"
                no-caret
                right
                v-if="isDashboard"
              >
                <template #button-content>
                  <div class="text-dark">
                    <i class="fas fa-cog"></i>
                  </div>
                </template>
                <div class="p-2">
                  <dashboardSettings />
                </div>
              </b-nav-item-dropdown>
            </li>
            -->
            <!-- notification section  -->
            <!--
            <li class="flex-grow-0">
              <b-nav-item-dropdown
                class="nav-item__hoverable"
                ref="notificationdrp"
                variant="link"
                no-caret
                right
                :disabled="!isSession"
              > -->
            <!-- Using 'button-content' slot -->
            <!--
                <template #button-content>
                  <notification-bell
                    class="text-center"
                    :size="21"
                    :count="counter"
                    :upperLimit="50"
                    counterLocation="upperRight"
                    counterStyle="roundRectangle"
                    counterBackgroundColor="#FF0000"
                    counterTextColor="#FFFFFF"
                    iconColor="#545454"
                    :animated="true"
                  />
                </template>
                <div>
                  <notifsBloc
                    v-if="isSession"
                    :showSettings="true"
                    :showActions="false"
                  />
                </div>
              </b-nav-item-dropdown>
            </li> -->
            <!-- User menu -->
            <li class="flex-grow-1">
              <b-nav-item-dropdown
                class="nav-user-menu nav-item__hoverable"
                right
              >
                <!-- Using 'button-content' slot -->
                <template #button-content>
                  <em class="text-dark" v-if="isLoggedIn">{{ user_name }}</em>
                  <em class="text-dark" v-else>{{ admin_user_name }}</em>
                </template>
                <!--<b-dropdown-item v-if="isLoggedIn" href="/profile">
                  {{ $t("nav.drop_down.profile") }}
                </b-dropdown-item> -->
                <b-dropdown-item v-if="isLoggedIn" href="/sessions">{{
                  $t("nav.drop_down.sessions")
                }}</b-dropdown-item>
                <b-dropdown-item
                  ><a v-b-modal.modal-language>{{
                    $t("nav.drop_down.lang")
                  }}</a></b-dropdown-item
                >
                <b-dropdown-item href="#" @click="logout">{{
                  $t("nav.drop_down.signout")
                }}</b-dropdown-item>
              </b-nav-item-dropdown>
            </li>
          </div>
        </ul>
        <ul v-else class="nav navbar-nav flex-row float-right">
          <li class="nav-item">
            <router-link class="nav-link pr-3 text-dark" to="/contact">{{
              $t("nav.contact")
            }}</router-link>
          </li>
          <li class="nav-item">
            <a
              class="nav-link pr-3 text-dark"
              href="https://www.fentech.ai"
              target="_blank"
              >{{ $t("nav.about") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <b-modal id="modal-language" :title="$t('lang.title')" @ok="setLen">
      <b-form-group :label="$t('lang.message')">
        <b-form-radio-group id="radio-group-1" v-model="lang">
          <b-form-radio value="fr"
            >Français<img
              :src="`/flag_fr.svg`"
              alt="flag"
              width="25"
              class="ml-3"
          /></b-form-radio>
          <b-form-radio value="en"
            >English<img
              :src="`/flag_en.svg`"
              alt="flag"
              width="25"
              class="ml-3"
          /></b-form-radio>
        </b-form-radio-group>

        <!-- <b-form-radio v-model="lang" value="fr">Francais<img  :src="`/flag_fr.svg`"  alt="flag"  width="25"  class="ml-3"/></b-form-radio>
        <b-form-radio v-model="lang" value="en">English<img  :src="`/flag_en.svg`"  alt="flag"  width="25"  class="ml-3"/></b-form-radio> -->
      </b-form-group>
    </b-modal>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

import DropDown from "@/components/Menu/DropDown.vue";
import { store, mutations } from "@/services/observable.js";
import pricing_routes from "@/_router/pricing_routes";

// import notifsBloc from "@/components/Notifications/NotifsBloc";
// import NotificationBell from "vue-notification-bell";
// import dashboardSettings from "@/components/Dashboard/Settings/settings";
export default {
  data() {
    return {
      limittedOperation: ["YSE", "ECOM"],
      company_access: {
        // possible options Pilotage, Operation, Prix, Lancement, Causality
        UADT: ["Pilotage", "Operation", "Prix", "Data"],
        YSE: ["Pilotage", "Operation", "Lancement"],
        GM: ["Pilotage", "Operation", "Prix", "Lancement"],
        PROPHOT: ["Pilotage", "Operation", "Prix"],
        ECOM: ["Pilotage", "Operation", "Prix", "Lancement"],
        RETAIL: ["Pilotage", "Operation", "Prix", "Lancement", "Causality"],
        RETAIL_PRICE: ["Pilotage", "Operation", "Lancement", "Causality"],
      },
      toggleNav: false,
      isNotif: false,
      lang: localStorage.getItem("lang") || "fr",
      headerLen: 0,

      links: [
        {
          label: "Link 1",
          to: "/path1",
        },
        {
          label: "Link 2",
          to: "/path2",
        },
        {
          label: "Link 3",
          to: "/path3",
        },
      ],
      pricing_href: "",
    };
  },
  created() {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "fr");
    }
  },
  components: {
    // notifsBloc,
    // NotificationBell,
    // dashboardSettings,
    DropDown,
  },

  mounted() {
    let company = this.$store.getters["users/company_name"];
    this.set_pricing_href(company);
    this.headerLen =
      this.$refs.headernav != undefined
        ? this.$refs.headernav.childElementCount
        : 0;
  },
  computed: {
    ...mapGetters({ isLoggedIn: "users/isLoggedIn" }),
    ...mapGetters({ company_name: "users/company_name" }),
    ...mapGetters({ isAdminLoggedIn: "users/isAdminLoggedIn" }),
    ...mapGetters({ user_name: "users/user_name" }),
    ...mapGetters({ admin_user_name: "users/adminUserName" }),
    ...mapGetters({ user_role: "users/role" }),
    ...mapGetters({ isSession: "master_data/isSession" }),
    ...mapGetters({ isDashboard: "dashboard/isDashboard" }),
    ...mapGetters({ notif_counter: "notifications/notif_counter" }),
    ...mapGetters({ company: "users/company" }),
    counter() {
      return Number(this.notif_counter);
    },
    link_border() {
      if (this.headerLen == 1) return "header-single";
      return "header-double";
    },
    hide_notif_drpdwn() {
      return store.hide_notif_drpdwn;
    },
  },
  watch: {
    hide_notif_drpdwn(val) {
      if (val) {
        this.$refs.notificationdrp.hide(true);
        mutations.hide_notif_drpdwn();
      }
    },
    company(val) {
      this.set_pricing_href(val);
    },
  },
  methods: {
    getOperationSubPages(companyName) {
      return this.limittedOperation.includes(companyName)
        ? [
            { label: this.$t("nav.ope.items[1]"), to: "/operation/pic" },
            { label: "Allocation", to: "/operation/allocation" },
            { label: "Merchandising", to: "/operation/merchandising" },
          ]
        : [
            { label: this.$t("nav.ope.items[1]"), to: "/operation/pic" },
            // { label: this.$t("nav.ope.items[2]"), to: "/operation/pdp" },
            { label: "Allocation", to: "/operation/allocation" },
            { label: "Merchandising", to: "/operation/merchandising" },
            { label: "Stock", to: "/operation/stock" },
            { label: this.$t("nav.ope.items[4]"), to: "/operation/supply" },
            { label: this.$t("nav.ope.items[6]"), to: "/operation/supplier" },
          ];
    },
    hasAccess(companyName, pageName) {
      return this.company_access[companyName]
        ? this.company_access[companyName].includes(pageName)
        : false;
    },
    set_pricing_href(company) {
      let href = pricing_routes[company];
      if (href) {
        this.pricing_href = pricing_routes[company];
      } else {
        this.pricing_href = pricing_routes["default"];
      }
    },
    logout: function () {
      this.$store.dispatch("users/logout").then(() => {
        document.location.href = "/";
      });
    },
    setLan(value) {
      localStorage.setItem("lang", value);
      this.$i18n.locale = value;
      // this.$router.push({
      //   params : {lang : value}
      // })
    },
    setLen() {
      localStorage.setItem("lang", this.lang);
      this.$i18n.locale = this.lang;
      location.reload();
    },
  },
};
</script>

<style>
.logo {
  width: 90px;
  height: auto;
  display: inline-block;
  vertical-align: top;
}

.main-nav {
  background-color: #fff;
  /* box-shadow: inset 0 -1px 0 0 #fff, 0 1px 5px rgba(0, 0, 0, 0.1); */
}

.red_bell {
  color: red;
}

.gray_bell {
  color: gray;
}

/* Style for Header with 1 button */
.header-single > .dropdown-container > a {
  border-radius: 5px !important;
}

/* Style for Header with more than 1 button */
.header-double > .dropdown-container:first-child > a {
  border-radius: 5px 0px 0px 5px !important;
}

.header-double > .dropdown-container:last-child > a {
  border-radius: 0px 5px 5px 0px !important;
}

/* Nav bar items  */

.nav-item {
  border-radius: 5px 5px 5px 5px !important;
  margin: 0px 2px;
}

.nav-item__hoverable:hover {
  background-color: lightgray;
  transition: background linear 250ms;
}
</style>
