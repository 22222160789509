<template>
  <div id="dashboard-content">
    <!-- Navbar -->
    <nav-bar id="navbar"></nav-bar>
    <!-- Dashboard content -->
    <div id="content">
      <router-view />
    </div>
    <!-- Footer -->
    <foo id="footer"></foo>
  </div>
</template>

<script>
import Navigation from "./layout/Navbar/navbar.vue";
import Footer from "./layout/Footer/Footer.vue";

export default {
  name: "App",
  components: {
    "nav-bar": Navigation,
    foo: Footer,
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  },
  methods: {
    preventNav(event) {
      let importing_merch = this.$store.getters["dataProcess/importing_merch"];
      let importing_launch =
        this.$store.getters["dataProcess/importing_launch"];
      if (importing_merch || importing_launch) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
  },
};
</script>

<style>
.main-content {
  min-height: 93vh;
  padding-top: 3.9rem;
  background-color: #f2f2f2;
  overflow-x: scroll;
  overflow-y: hidden;
}

html,
body {
  width: 100%;
  height: 100%;
}

#dashboard-content {
  background-color: #f2f2f2;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 3rem;
}

#content {
  flex-grow: 1;
  display: flex;
}

#navbar,
#content,
#footer {
  flex-shrink: 0;
}
</style>
