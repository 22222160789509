import Vue from "vue";

export const store = Vue.observable({
  isNavOpen: false,
  isAvailableSessionInfo: false,
  filtered: false,
  isOkML: false,
  data_loaded: false,
  isNewScenarioData: false,
  step: 0,
  is_specials: false,
  isNewHowTo: false,
  isNewWhatIf: false,
  showImpactsModal: false,
  isPDF: false,
  refChange: false,
  isGraphChanged: false,
  isScenarioChanged: false,
  isImpactChanged: false,
  ok_load_dashboard: false,
  refSalesChange: false,
  isOnChangePDP: false,
  isOnChangePIC: false,
  isLoadedNotif: false,
  isCloseScenarioPanel: false,
  reset_graphs: false,
  resetLifeStock: false,
  resetDashboardGraph: false,
  hide_notif_drpdwn: false,
  send_edit_pic_request: false,
  isNewSeason: false,
  hanoutItemSelected: "Sales",
  update_suppliers_impacts: false,
});

export const mutations = {
  refSalesChange() {
    store.refSalesChange = !store.refSalesChange;
  },
  isDashboardLoad(state) {
    store.ok_load_dashboard = state;
  },
  isScenarioChanged() {
    store.isScenarioChanged = !store.isScenarioChanged;
  },
  isImpactChanged() {
    store.isImpactChanged = !store.isImpactChanged;
  },
  isGraphChanged() {
    store.isGraphChanged = !store.isGraphChanged;
  },
  isPDF(val) {
    store.isPDF = val
  },
  refChange() {
    store.refChange = !store.refChange;
  },
  isNewHowTo(val) {
    store.isNewHowTo = val
  },
  isNewWhatIf() {
    store.isNewWhatIf = !store.isNewWhatIf
  },
  setStep(step) {
    store.step = step
  },
  setHanoutItem(item) {
    store.hanoutItemSelected = item;
  },
  setIsNavOpen(yesno) {
    store.isNavOpen = yesno;
  },
  toggleNav() {
    store.isNavOpen = !store.isNavOpen;
  },
  openCloseScenarioPanel() {
    store.isCloseScenarioPanel = !store.isCloseScenarioPanel
  },
  got_session_info() {
    store.isAvailableSessionInfo = !store.isAvailableSessionInfo;
  },
  isDataFiltered() {
    store.filtered = true;
  },
  isOkML() {
    store.isOkML = !store.isOkML;
  },
  is_data_loaded() {
    store.data_loaded = true
  },
  isNewScenarioData() {
    store.isNewScenarioData = !store.isNewScenarioData;
  },
  isOnChangePDP() {
    store.isOnChangePDP = !store.isOnChangePDP;
  },
  isOnChangePIC() {
    store.isOnChangePIC = !store.isOnChangePIC;
  },
  isSpecial() {
    store.is_specials = true
  },
  showImpactsModal(show) {
    store.showImpactsModal = show
  },
  isLoadedNotif() {
    store.isLoadedNotif = !store.isLoadedNotif
  },
  reset_graphs() {
    store.reset_graphs = !store.reset_graphs
  },
  resetLifeStock() {
    store.resetLifeStock = !store.resetLifeStock
  },
  resetDashboardGraph() {
    store.resetDashboardGraph = !store.resetDashboardGraph
  },
  hide_notif_drpdwn() {
    store.hide_notif_drpdwn = !store.hide_notif_drpdwn
  },
  send_edit_pic_request() {
    store.send_edit_pic_request = !store.send_edit_pic_request
  },
  isNewSeason(val) {
    store.isNewSeason = val
  },
  update_suppliers_impacts(val) {
    store.update_suppliers_impacts = val
  }
};
