
export const master_data = {
    namespaced : true,
    state: {
        isSession : false,


    },
    mutations: {
        set_isSession(state, isSession) {
            state.isSession = isSession
        },

    },
    actions: {
        save_isSession({ commit }, isSession) {
            commit('set_isSession', isSession)
        },
    },
    getters:{
        isSession: state => state.isSession,

    }
}