
export const notifications = {
    namespaced : true,
    state: {
        displayed_notifs_by_level: JSON.parse(localStorage.getItem('displayed_notifs_by_level' || "['Major', 'Minor','Moderate']")),
        displayed_notifs_by_type: JSON.parse(localStorage.getItem('displayed_notifs_by_type' || "['EOL','OOS','OS','ST','MN']")),
        notifications : JSON.parse(localStorage.getItem('notifications' || "")),
        notif_counter : null,
        oos_notifs_settings :  JSON.parse(localStorage.getItem('oos_notifs_settings' || "")),
        eol_notifs_settings :  JSON.parse(localStorage.getItem('eol_notifs_settings' || "")),
        os_notifs_settings :  JSON.parse(localStorage.getItem('os_notifs_settings' || "")),
        st_notifs_settings :  JSON.parse(localStorage.getItem('st_notifs_settings' || "")),
        mn_notifs_settings :  JSON.parse(localStorage.getItem('mn_notifs_settings' || "")),
        eol_notifs_settings_updated :  false,
        oos_notifs_settings_updated :  false,
        os_notifs_settings_updated :  false,
        st_notifs_settings_updated :  false,
        mn_notifs_settings_updated :  false,
        counters_per_types_levels : {},
        show_eol_settings : JSON.parse(localStorage.getItem('show_eol_settings' || true)),
        show_oos_settings : JSON.parse(localStorage.getItem('show_oos_settings' || true)),
        show_os_settings : JSON.parse(localStorage.getItem('show_os_settings' || true)),
        show_st_settings : JSON.parse(localStorage.getItem('show_st_settings' || true)),
        show_mn_settings : JSON.parse(localStorage.getItem('show_mn_settings' || true)),
    },
    mutations: {
        save_displayed_notifs_by_level(state, displayed_notifs_by_level) {
            state.displayed_notifs_by_level = displayed_notifs_by_level
            localStorage.setItem('displayed_notifs_by_level', JSON.stringify(displayed_notifs_by_level))
        },
        save_displayed_notifs_by_type(state, displayed_notifs_by_type) {
            state.displayed_notifs_by_type = displayed_notifs_by_type
            localStorage.setItem('displayed_notifs_by_type', JSON.stringify(displayed_notifs_by_type))
        },
        save_notifications(state, notifications) {
            state.notifications = notifications
            localStorage.setItem('notifications', JSON.stringify(notifications))
        },
        save_notif_counter(state, notif_counter) {
            state.notif_counter = notif_counter
        },
        save_counters_per_types_levels(state, counter){
            state.counters_per_types_levels = counter
        },
        save_oos_notifs_settings(state, oos_notifs_settings) {
            state.oos_notifs_settings = oos_notifs_settings
            localStorage.setItem('oos_notifs_settings', JSON.stringify(oos_notifs_settings))
        },
        save_eol_notifs_settings(state, eol_notifs_settings) {
            state.eol_notifs_settings = eol_notifs_settings
            localStorage.setItem('eol_notifs_settings', JSON.stringify(eol_notifs_settings))
        },
        save_os_notifs_settings(state, os_notifs_settings) {
            state.os_notifs_settings = os_notifs_settings
            localStorage.setItem('os_notifs_settings', JSON.stringify(os_notifs_settings))
        },
        save_st_notifs_settings(state, st_notifs_settings) {
            state.st_notifs_settings = st_notifs_settings
            localStorage.setItem('st_notifs_settings', JSON.stringify(st_notifs_settings))
        },
        save_mn_notifs_settings(state, mn_notifs_settings) {
            state.mn_notifs_settings = mn_notifs_settings
            localStorage.setItem('mn_notifs_settings', JSON.stringify(mn_notifs_settings))
        },
        save_eol_notifs_settings_updated(state, eol_notifs_settings) {
            state.eol_notifs_settings_updated = eol_notifs_settings
        },
        save_st_notifs_settings_updated(state, st_notifs_settings) {
            state.st_notifs_settings_updated = st_notifs_settings
        },
        save_mn_notifs_settings_updated(state, mn_notifs_settings) {
            state.mn_notifs_settings_updated = mn_notifs_settings
        },
        save_show_eol_settings(state, data) {
            state.show_eol_settings = data
            localStorage.setItem('show_eol_settings', JSON.stringify(data))
        },
        save_show_oos_settings(state, data) {
            state.show_oos_settings = data
            localStorage.setItem('show_oos_settings', JSON.stringify(data))
        },
        save_show_os_settings(state, data) {
            state.show_os_settings = data
            localStorage.setItem('show_os_settings', JSON.stringify(data))
        },
        save_show_st_settings(state, data) {
            state.show_st_settings = data
            localStorage.setItem('show_st_settings', JSON.stringify(data))
        },
        save_show_mn_settings(state, data) {
            state.show_mn_settings = data
            localStorage.setItem('show_mn_settings', JSON.stringify(data))
        },        
    },
    actions: {
        set_displayed_notifs_by_level({ commit }, displayed_notifs_by_level) {
            commit('save_displayed_notifs_by_level', displayed_notifs_by_level)
        },
        set_displayed_notifs_by_type({ commit }, displayed_notifs_by_type) {
            commit('save_displayed_notifs_by_type', displayed_notifs_by_type)
        },
        set_notifications({ commit }, notifications) {
            commit('save_notifications', notifications)
        },
        set_notif_counter({ commit }, notif_counter) {
            commit('save_notif_counter', notif_counter)
        },
        set_notif_counters_per_types_levels({ commit }, counter) {
            commit('save_counters_per_types_levels', counter)
        },
        set_oos_notifs_settings({ commit }, oos_notifs_settings) {
            commit('save_oos_notifs_settings', oos_notifs_settings)
        },
        set_eol_notifs_settings({ commit }, eol_notifs_settings) {
            commit('save_eol_notifs_settings', eol_notifs_settings)
        },
        set_os_notifs_settings({ commit }, os_notifs_settings) {
            commit('save_os_notifs_settings', os_notifs_settings)
        },
        set_st_notifs_settings({ commit }, st_notifs_settings) {
            commit('save_st_notifs_settings', st_notifs_settings)
        },
        set_mn_notifs_settings({ commit }, mn_notifs_settings) {
            commit('save_mn_notifs_settings', mn_notifs_settings)
        },
        is_eol_notifs_settings_updated({ commit }, notifs_settings) {
            commit('save_eol_notifs_settings_updated', notifs_settings)
        },
        is_oos_notifs_settings_updated({ commit }, notifs_settings) {
            commit('save_oos_notifs_settings_updated', notifs_settings)
        },
        is_os_notifs_settings_updated({ commit }, notifs_settings) {
            commit('save_os_notifs_settings_updated', notifs_settings)
        },
        is_st_notifs_settings_updated({ commit }, notifs_settings) {
            commit('save_st_notifs_settings_updated', notifs_settings)
        },
        is_mn_notifs_settings_updated({ commit }, notifs_settings) {
            commit('save_mn_notifs_settings_updated', notifs_settings)
        },
        set_show_eol_settings({ commit }, data) {
            commit('save_show_eol_settings', data)
        },
        set_show_oos_settings({ commit }, data) {
            commit('save_show_oos_settings', data)
        },
        set_show_os_settings({ commit }, data) {
            commit('save_show_os_settings', data)
        },
        set_show_st_settings({ commit }, data) {
            commit('save_show_st_settings', data)
        },
        set_show_mn_settings({ commit }, data) {
            commit('save_show_mn_settings', data)
        },
    },
    getters:{
        displayed_notifs_by_level: state => state.displayed_notifs_by_level,
        displayed_notifs_by_type: state => state.displayed_notifs_by_type,
        notifications: state => state.notifications,
        notif_counter: state => state.notif_counter,
        counters_per_types_levels: state => state.counters_per_types_levels,
        oos_notifs_settings: state => state.oos_notifs_settings,
        eol_notifs_settings: state => state.eol_notifs_settings,
        os_notifs_settings: state => state.os_notifs_settings,
        mn_notifs_settings: state => state.mn_notifs_settings,
        st_notifs_settings: state => state.st_notifs_settings,
        eol_notifs_settings_updated: state => state.eol_notifs_settings_updated,
        oos_notifs_settings_updated: state => state.oos_notifs_settings_updated,
        os_notifs_settings_updated: state => state.os_notifs_settings_updated,
        st_notifs_settings_updated: state => state.st_notifs_settings_updated,
        mn_notifs_settings_updated: state => state.mn_notifs_settings_updated,
        show_eol_settings: state => state.show_eol_settings,
        show_oos_settings: state => state.show_oos_settings,
        show_os_settings: state => state.show_os_settings,
        show_st_settings: state => state.show_st_settings,
        show_mn_settings: state => state.show_mn_settings,
    }
}