export const users = {
	namespaced: true,
	state: {
		token: localStorage.getItem("token") || "",
		status: "",
		user_id: localStorage.getItem("userID") || "",
		user_name: localStorage.getItem("user_name") || "",
		last_name: localStorage.getItem("last_name") || "",
		first_name: localStorage.getItem("first_name") || "",
		phone: localStorage.getItem("phone") || "",
		email: localStorage.getItem("email") || "",
		company: localStorage.getItem("company") || "",
		company_name: localStorage.getItem("company_name") || "",
		position: localStorage.getItem("position") || "",
		department: localStorage.getItem("department") || "",
		role: localStorage.getItem("role") || [],
		isAdminLoggedIn: localStorage.getItem("isAdminLoggedIn", false),
		adminUserName: localStorage.getItem("adminUserName", ""),
		selectedSeasonsList: [],
		seasonsList: [],
		parent_user: localStorage.getItem("parent_user", ""),
		SupplierName: localStorage.getItem("SupplierName", ""),
		companyName: ""
	},
	mutations: {
		auth_request(state) {
			state.status = "loading";
		},
		auth_success(state, data) {
			state.status = "success";
			state.token = data.token;
			state.user_id = data.user_id;
			state.user_name = data.user_name;
			state.first_name = data.first_name;
			state.last_name = data.last_name;
			state.phone = data.phone;
			state.email = data.email;
			state.company = data.company;
			state.company_name = data.company_name;
			state.position = data.position;
			state.department = data.department;
			state.role = data.role;
			state.parent_user = data.parent_user;
		},
		set_profile(state, data) {
			state.first_name = data.first_name;
			state.last_name = data.last_name;
			state.phone = data.phone;
			state.email = data.email;
			state.company = data.company;
			state.company_name = data.company_name;
			state.position = data.position;
			state.department = data.department;
			state.role = data.role;
		},
		auth_error(state) {
			state.status = "error";
		},
		logout(state) {
			state.status = "";
			state.token = "";
			localStorage.removeItem("token");
			localStorage.removeItem("userID");
			state.isAdminLoggedIn = false;
			state.adminUserName = "";
			localStorage.setItem("isAdminLoggedIn", false);
			localStorage.setItem("adminUserName", "");
		},
		save_admin_username(state, adminUserName) {
			state.adminUserName = adminUserName;
			localStorage.setItem("adminUserName", adminUserName);
		},
		save_admin_loggedIn(state, isAdminLoggedIn) {
			state.isAdminLoggedIn = isAdminLoggedIn;
			localStorage.setItem("isAdminLoggedIn", isAdminLoggedIn);
		},
		/**
		 * Sets selected seasons to be shown.
		 * This parameter must be user specific value.
		 * Each user can have a different list of seasons.
		 *
		 * - seasonsList (Array): The list that contains the selected seasons.
		 */
		setSelectedSeasonsList(state, seasonsList) {
			if (seasonsList) state.selectedSeasonsList = seasonsList;
		},
		setSeasonsList(state, seasonsList) {
			if (seasonsList) state.seasonsList = seasonsList;
		},
		setSupplierName(state, data) {
			state.SupplierName = data;
		},
		setParentID(state, data) {
			localStorage.setItem("parent_user", data);
			state.parent_user = data;
		},
		setCompanyName(state, data) {
			state.companyName = data
		},
	},
	actions: {
		auth_success({ commit }, data) {
			commit("auth_success", data);
		},
		set_profile({ commit }, profile) {
			localStorage.setItem("last_name", profile.last_name);
			localStorage.setItem("first_name", profile.first_name);
			localStorage.setItem("phone", profile.phone);
			localStorage.setItem("email", profile.email);
			localStorage.setItem("company", profile.company);
			localStorage.setItem("company_name", profile.company_name);
			localStorage.setItem("position", profile.position);
			localStorage.setItem("department", profile.department);
			localStorage.setItem("role", profile.role);
			commit("set_profile", profile);
		},
		// login({ commit }, user) {
		//     return new Promise((resolve, reject) => {
		//         commit('auth_request')
		//         axios({ url: process.env.VUE_APP_URL_BACK + '/api/login', data: user, method: 'POST' })
		//             .then(resp => {
		//                 const token = resp.data.token
		//                 const user_id = resp.data.user_id
		//                 const user_name = resp.data.user_profile['username']
		//                 const department = resp.data.user_profile['department']
		//                 const role = resp.data.user_profile['group']
		//                 const position = resp.data.user_profile['position']
		//                 const company = resp.data.user_profile['company']
		//                 const email = resp.data.user_profile['email']
		//                 const phone = resp.data.user_profile['phone']
		//                 const last_name = resp.data.user_profile['last_name']
		//                 const first_name = resp.data.user_profile['first_name']

		//                 localStorage.setItem('token', token)
		//                 localStorage.setItem('userID', user_id)
		//                 localStorage.setItem('last_name', last_name)
		//                 localStorage.setItem('first_name', first_name)
		//                 localStorage.setItem('user_name', user_name)
		//                 localStorage.setItem('phone', phone)
		//                 localStorage.setItem('email', email)
		//                 localStorage.setItem('company', company)
		//                 localStorage.setItem('position', position)
		//                 localStorage.setItem('department', department)
		//                 localStorage.setItem('role', role)

		//                 axios.defaults.headers.common['Authorization'] = token
		//                 const data = { 'token': token, 'user_id': user_id, 'department': department, 'role': role, 'position': position, 'company': company, 'email': email, 'phone': phone, 'first_name': first_name, 'last_name': last_name, 'user_name': user_name }
		//                 commit('auth_success', data)
		//                 resolve(resp)
		//             })
		//             .catch(err => {
		//                 commit('auth_error')
		//                 localStorage.removeItem('token')
		//                 reject(err)
		//                 ///alert("An error has occured, please try again")
		//                 //this.$router.go()
		//             })
		//     })
		// },
		logout({ commit }) {
			return new Promise((resolve) => {
				commit("logout");
				// localStorage.removeItem('token')
				localStorage.clear();
				// delete this.$axios.defaults.headers.common['Authorization']
				resolve();
			});
		},
		set_admin_username({ commit }, adminUserName) {
			commit("save_admin_username", adminUserName);
		},
		set_admin_loggedIn({ commit }, isAdminLoggedIn) {
			commit("save_admin_loggedIn", isAdminLoggedIn);
		},
		set_SupplierName({ commit }, data) {
			commit("saveSupplierName", data);
		},
		set_parent_user({ commit }, data) {
			commit("saveParentID", data);
		},
		set_company_name({ commit }, data) {
			commit("setCompanyName", data);
		},
	},
	getters: {
		token: (state) => state.token,
		authStatus: (state) => state.status,
		user_id: (state) => state.user_id,
		department: (state) => state.department,
		role: (state) => state.role,
		position: (state) => state.position,
		company: (state) => state.company,
		company_name: (state) => state.company_name,
		email: (state) => state.email,
		phone: (state) => state.phone,
		last_name: (state) => state.last_name,
		first_name: (state) => state.first_name,
		user_name: (state) => state.user_name,
		isLoggedIn: (state) => !!state.token,
		adminUserName: (state) => state.adminUserName,
		isAdminLoggedIn: (state) => state.isAdminLoggedIn,
		selectedSeasonsList: (state) => state.selectedSeasonsList,
		seasonsList: (state) => state.seasonsList,
		parent_user: (state) => state.parent_user,
		SupplierName: (state) => state.SupplierName,
		companyName: (state) => state.companyName
	},
};
