export const dataProcess = {
    namespaced: true,
    state: {
        estimated_time: localStorage.getItem('estimated_time' || 0),
        ok_input_file: localStorage.getItem('ok_input_file' || ''),
        ok_dashboard: localStorage.getItem('ok_dashboard' || ''),
        ok_cols: localStorage.getItem('ok_cols' || ''),
        ok_forecast: localStorage.getItem('ok_forecast' || ''),
        ml_horizon: localStorage.getItem('ml_horizon' || ''),
        ml_gran: localStorage.getItem('ml_gran' || ''),
        importing_launch: false,
        importing_merch: false,
    },
    mutations: {
        ok_dashboard(state, ok_dashboard) {
            state.ok_dashboard = ok_dashboard
            localStorage.setItem('ok_dashboard', ok_dashboard)
        },
        ok_forecast(state, ok_forecast) {
            state.ok_forecast = ok_forecast
            localStorage.setItem('ok_forecast', ok_forecast)
        },
        ok_cols(state, ok_cols) {
            state.ok_cols = ok_cols
            localStorage.setItem('ok_cols', ok_cols)
        },
        ok_input_file(state, ok_input_file) {
            state.ok_input_file = ok_input_file
            localStorage.setItem('ok_input_file', ok_input_file)
        },
        ml_horizon(state, ml_horizon) {
            state.ml_horizon = ml_horizon
            localStorage.setItem('ml_horizon', ml_horizon)
        },
        ml_gran(state, ml_gran) {
            state.ml_gran = ml_gran
            localStorage.setItem('ml_gran', ml_gran)
        },
        estimated_time(state, estimated_time) {
            state.estimated_time = estimated_time
        },
        set_importing_merch(state, importing) {
            state.importing_merch = importing
        },
        set_importing_launch(state, importing) {
            state.importing_launch = importing
        },
    },
    actions: {
        ok_dashboard({ commit }, ok_dashboard) {
            commit('ok_dashboard', ok_dashboard)
        },
        ok_forecast({ commit }, ok_forecast) {
            commit('ok_forecast', ok_forecast)
        },
        ok_cols({ commit }, ok_cols) {
            commit('ok_cols', ok_cols)
        },
        ok_input_file({ commit }, ok_input_file) {
            commit('ok_input_file', ok_input_file)
        },

        set_ml_horizon({ commit }, ml_horizon) {
            commit('ml_horizon', ml_horizon)
        },
        set_ml_gran({ commit }, ml_gran) {
            commit('ml_gran', ml_gran)
        },
        set_estimated_time({ commit }, estimated_time) {
            commit('estimated_time', estimated_time)
            localStorage.setItem('estimated_time', estimated_time)
        },
        SET_IMPORTING_MERCH({ commit }, importing) {
            commit('set_importing_merch', importing)
        },
        SET_IMPORTING_LAUNCH({ commit }, importing) {
            commit('set_importing_launch', importing)
        },
    },
    getters: {
        estimated_time: state => state.estimated_time,
        ok_input_file: state => state.ok_input_file,
        ok_dashboard: state => state.ok_dashboard,
        ok_cols: state => state.ok_cols,
        ok_forecast: state => state.ok_forecast,
        ml_horizon: state => state.ml_horizon,
        ml_gran: state => state.ml_gran,
        importing_merch: state => state.importing_merch,
        importing_launch: state => state.importing_launch,
    }
}