import Vue from "vue";
import VueRouter from "vue-router";
import store from "../_store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/admin",
    component: () => import("../views/Admin-Panel/PanelAdmin.vue"),
    children: [
      {
        path: "",
        name: "adminLogin",
        component: () => import("../views/Admin-Panel/LoginAdmin.vue"),
      },

      /* {
        path: 'dashboard',
        name: 'adminDashboard',
        component: () => import('../views/Admin-Panel/DashboardAdmin.vue'),
      }, */

      {
        path: "users",
        name: "adminUsers",
        component: () => import("../views/Admin-Panel/UserManagement.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/Login/Login.vue"),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login/Login.vue"),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/sessions",
    name: "sessions",
    component: () => import("../views/Sessions/sessions.vue"),
  },
  {
    path: "/vision-fournisseur",
    name: "vision-fournisseur",
    component: () => import("../views/Vision-Supplier/home.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Users/UserProfile.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/Notifications/Notifications.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard/dashboard.vue"),
  },
  {
    path: "/data-process",
    component: () => import("../views/Data-process/dataProcess.vue"),
    children: [
      {
        path: "",
        name: "steps",
        component: () => import("../views/Data-process/dataInput.vue"),
      },
      {
        path: "data-input",
        name: "datainput",
        component: () => import("../views/Data-process/dataInput.vue"),
      },
      {
        path: "data-preview",
        name: "dataprev",
        component: () => import("../views/Data-process/dataPreview.vue"),
      },

      {
        path: "data-validation",
        name: "dataval",
        component: () => import("../views/Data-process/dataValidation.vue"),
      },

      {
        path: "forecasting",
        name: "forecast",
        component: () => import("../views/Data-process/dataPendingPage.vue"),
      },
    ],
  },
  {
    path: "/master",
    name: "master",
    component: () => import("../views/Master-data/master.vue"),
  },
  {
    path: "/home",
    name: "home_page",
    component: () => import("../views/Home/home.vue"),
  },
  {
    path: "/pilotage",
    name: "pilotage_page",
    component: () => import("../views/Pilotage/home.vue"),
  },
  {
    path: "/pilotage/catalogue",
    name: "pilotage_catalogue",
    component: () => import("../views/Pilotage/budget.vue"),
    // component: () => import('../views/pilotage/catalogue.vue')
  },
  // {
  //   path: "/pilotage/sop",
  //   name: "pilotage_sop",
  //   props: {
  //     sop: true,
  //   },
  //   component: () => import("../views/Pilotage/pilotage.vue"),
  // },
  {
    path: "/pilotage/sop",
    name: "pilotage_sop_new",
    props: {
      sop: true,
    },
    component: () => import("../views/Pilotage/Sop.vue"),
  },
  {
    path: "/pilotage/consensus",
    name: "pilotage_consensus",
    component: () => import("../views/Pilotage/Consensus.vue"),
  },
  /* {
    path: "/pilotage/events",
    name: "pilotage_events",
    component: () => import("../views/Pilotage/Events.vue"),
  }, */
  {
    path: "/pilotage/event",
    name: "pilotage_event",
    component: () => import("../views/Pilotage/Events.vue"),
  },
  {
    path: "/pilotage/lifecycle",
    name: "pilotage_life",
    props: {
      isLife: true,
    },
    component: () => import("../views/Operation/operation.vue"),
  },
  {
    path: "/operation",
    name: "operation_page",
    component: () => import("../views/Operation/home.vue"),
  },

  {
    path: "/operation/pdp",
    name: "operation_pdp",
    props: {
      isPdp: true,
    },
    component: () => import("../views/Operation/operation.vue"),
  },
  {
    path: "/operation/supplier",
    name: "supplier_page",
    component: () => import("../views/Operation/Supplier.vue"),
  },

  {
    path: "/operation/pic",
    name: "operation_pic",
    props: {
      isPic: true,
    },
    component: () => import("../views/Operation/operation.vue"),
  },
  {
    path: "/operation/allocation",
    name: "operation_allocation",
    props: {
      isAllocation: true,
    },
    component: () => import("../views/Operation/operation.vue"),
  },
  {
    path: "/operation/merchandising",
    name: "operation_merch",
    props: {
      isMerch: true,
    },
    component: () => import("../views/Operation/operation.vue"),
  },
  {
    path: "/operation/stock",
    name: "operation_stock",
    props: {
      isStock: true,
    },
    component: () => import("../views/Operation/operation.vue"),
  },
  {
    path: "/operation/drp/mov",
    name: "operation_drp_mouv",
    component: () => import("../views/Operation/movement.vue"),
  },
  {
    path: "/operation/supply",
    name: "operation_supply",
    props: {
      isSupply: true,
    },
    component: () => import("../views/Operation/operation.vue"),
  },
  /* {
    path: '/operation/lifecycle',
    name: 'operation_life',
    props: { isLife: true },
    component: () => import('../views/Operation/operation.vue')
  }, */
  {
    path: "/price",
    name: "price_home",
    component: () => import("../views/Price/NewHome.vue"),
  },
  {
    path: "/price/products",
    name: "price_page",
    component: () => import("../views/Price/price.vue"),
  },
  {
    path: "/launch",
    name: "launch_home",
    component: () => import("../views/Launch/home.vue"),
  },
  {
    path: "/launch/page",
    name: "launch_page",
    props: {
      isLaunch: true,
    },
    component: () => import("../views/Launch/launch.vue"),
  },
  {
    path: "/launch/product",
    name: "launch_product",
    props: {
      isLaunch: true,
    },
    component: () => import("../views/Launch/product.vue"),
  },
  {
    path: "/launch/campaign",
    name: "launch_campaign",
    props: {
      isLaunch: true,
    },
    component: () => import("../views/Launch/campaign.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Pages/contact_us.vue"),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/sales-sessions",
    name: "sales-sessions",
    component: () => import("../views/Salesman/sales_session.vue"),
  },
  {
    path: "/sales-dashboard",
    name: "sales-dashboard",
    component: () => import("../views/Salesman/sales_dashboard.vue"),
  },
  {
    path: "/hanout",
    name: "hanout",
    component: () => import("../views/Hanout/hanoutHome.vue"),
  },
  {
    path: "/update_data",
    name: "update_data_page",
    meta: {
      resource: "Data",
    },
    component: () => import("@/components/Meta/UpdateData.vue"),
  },
  // Causality
  {
    path: "/causality",
    name: "causality",
    component: () => import("@/views/causality/Causality.vue"),
  },
  {
    path: "/causality/testing-analysis",
    name: "testing-analysis",
    component: () => import("@/views/causality/TestingAnalysis.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == "adminLogin") {
    store.dispatch("users/logout");
    next();
  } else if (to.name == "adminUsers") {
    if (!store.getters["users/isAdminLoggedIn"]) {
      next({
        path: "/admin",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    if (!to.meta.allowAnonymous && !store.getters["users/isLoggedIn"]) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      if (
        (to.name == "sales-sessions" || to.name == "sales-dashboard") &&
        !store.getters["users/role"].includes("CompanyAdmin")
      ) {
        next({
          path: "/",
          query: {
            redirect: to.fullPath,
          },
        });
      } else if (
        to.name == "vision-fournisseur" &&
        !store.getters["users/role"].includes("Supplier")
      ) {
        next({
          path: "/",
          query: {
            redirect: to.fullPath,
          },
        });
      } else if (
        to.name == "sessions" &&
        store.getters["users/role"].includes("Supplier")
      ) {
        next({
          path: "/vision-fournisseur",
          query: {
            redirect: to.fullPath,
          },
        });
      } else if (
        (to.name == "master" || to.name == "dashboard") &&
        store.getters["sessions/save_id"] == ""
      ) {
        next({
          path: "/sessions",
          query: {
            redirect: to.fullPath,
          },
        });
      } else if (
        to.path.startsWith("/pilotage") &&
        !(
          store.getters["users/role"].includes("CompanyAdmin") ||
          store.getters["users/role"].includes("Pilotage")
        )
      ) {
        next({
          path: "/",
          query: {
            redirect: to.fullPath,
          },
        });
      } else if (
        to.path.startsWith("/operation") &&
        !(
          store.getters["users/role"].includes("Operation") ||
          store.getters["users/role"].includes("CompanyAdmin")
        )
      ) {
        next({
          path: "/",
          query: {
            redirect: to.fullPath,
          },
        });
      } else if (
        to.path.startsWith("/price") &&
        !(
          store.getters["users/role"].includes("Prix") ||
          store.getters["users/role"].includes("CompanyAdmin")
        )
      ) {
        next({
          path: "/",
          query: {
            redirect: to.fullPath,
          },
        });
      } else if (
        to.path.startsWith("/launch") &&
        !(
          store.getters["users/role"].includes("Lancement") ||
          store.getters["users/role"].includes("CompanyAdmin")
        )
      ) {
        next({
          path: "/",
          query: {
            redirect: to.fullPath,
          },
        });
      } else {
        next();
      }
    }
  }
});
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    router.app.$nprogress.start();
    router.app.$nprogress.set(0.1);
  }
  next();
});
router.afterEach(() => {
  // Complete the animation of the route progress bar.
  setTimeout(() => router.app.$nprogress.done(), 500);
});

export default router;
