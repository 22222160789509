<template>
  <!-- ------------------ -->
  <!-- Dropdown container -->
  <!-- ------------------ -->
  <div
    @mouseover="toggle = true"
    @mouseleave="toggle = false"
    class="dropdown-container flex-column"
  >
    <!-- ------------------ -->
    <!--   Dropdown Link    -->
    <!-- ------------------ -->
    <router-link
      v-if="to"
      class="btn btn-outline-secondary btn-green-hoverable border rounded-0"
      :to="to"
    >
      <div class="d-flex align-items-center gap-2">
        <i v-if="icon" :class="`fas fa-${icon}`"></i>
        <span class="ml-2">{{ title }}</span>
      </div>
    </router-link>
    <a
      v-else
      :href="href"
      class="btn btn-outline-secondary btn-green-hoverable border rounded-0"
    >
      <div class="d-flex align-items-center gap-2">
        <i v-if="icon" :class="`fas fa-${icon}`"></i>
        <span class="ml-2">{{ title }}</span>
      </div>
    </a>
    <!-- ----------------------- -->
    <!-- Dropdown content (list) -->
    <!-- ----------------------- -->
    <div
      v-if="links"
      :class="`dropdown-content dropdown-content-position border header ${
        toggle ? 'd-block d-md-flex' : 'd-none'
      }`"
    >
      <div v-for="(item, ind) in links" :key="item.label">
        <router-link v-if="ind == 0" class="drop-home" :to="item.to">
          {{ item.label }}
        </router-link>
        <router-link v-if="ind != 0 && !item.links" :to="item.to">
          {{ item.label }}
        </router-link>
        <div
          v-if="ind != 0 && item.links"
          class="dropdown-container dropright flex-column"
        >
          <a
            :href="href"
            class="
              btn btn-outline-none
              border-0
              btn-green-hoverable
              border
              rounded-0
              submenu-title
              pl-0
            "
            data-toggle="dropdown"
          >
            {{ item.label }}
            <b-icon icon="chevron-right" small style="height: 15px"></b-icon>
          </a>

          <div
            class="dropdown-menu dropdown-content-submenu"
            aria-labelledby="dropdownMenuLink"
          >
            <div v-for="subItem in item.links" :key="subItem.label">
              <router-link :to="subItem.to">
                {{ subItem.label }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    to: {
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    links: {
      type: Array,
      default: null,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      toggle: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/css/_variables.scss";

.dropright:hover .dropdown-menu {
  display: block;
  margin-top: 0; // remove the gap so it doesn't close
}

.btn-green-hoverable:hover {
  background: $fentech-lightgreen-color;
  transition: background linear 250ms;
}

.dropdown-container {
  display: flex;
  gap: 2px;
  flex-grow: 1;
  width: 100%;
  text-align: center;
  position: relative;
}

.dropdown-content-submenu {
  flex-direction: column;
  gap: 5px;
  background: white;
  border-radius: 5px;
  padding: 4px;
  text-align: left;
  text-decoration: none;
}
.dropdown-content {
  flex-direction: column;
  gap: 5px;
  background: white;
  border-radius: 5px;
  padding: 4px;
  top: 100%;
  text-align: left;
  animation: growDown 300ms ease-in-out forwards;
  text-decoration: none;
}

.submenu-title {
  display: block;
  text-decoration: none !important;
  color: #2c3e50;
}

.dropdown-content-submenu > div > a {
  display: block;
  text-decoration: none !important;
  color: #2c3e50;
  padding: 5px 20px;
}

.dropdown-content > div > a {
  display: block;
  text-decoration: none !important;
  color: #2c3e50;
  padding: 5px 20px;
}

.dropdown-content-submenu > div > a:hover {
  background: $secondary-gradient !important;
  color: white !important;
  border-radius: 5px;
}

.dropdown-content > div > a:hover {
  background: $secondary-gradient !important;
  color: white !important;
  border-radius: 5px;
}

.router-link-active-exact {
  background: $secondary-gradient !important;
  color: white !important;
  border-radius: 5px;
}

.router-link-active:not(.drop-home) {
  background: $secondary-gradient !important;
  color: white !important;
  border-radius: 5px;
}

.btn:focus {
  outline: none !important;
  box-shadow: 0 !important;
}

/* Dropdown menu animation */
@keyframes growDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.header.dropdown-content {
  min-width: 180px;
}
</style>