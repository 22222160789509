
export const hanout = {
    
    namespaced: true,

    state: {
        product: false,
        sales: false,
        stock: false,
        suppliers: false,
        warehouses: false,
        stores: false,
        pricing: false,
        predict: 0,
    },
    mutations: {
        SET_PRODUCT(state, value) {
            state.product = value
        },
        SET_SALES(state, value) {
            state.sales = value
        },
        SET_SUPPLIERS(state, value) {
            state.suppliers = value
        },
        SET_STOCK(state, value) {
            state.stock = value
        },
        SET_WAREHOUSES(state, value) {
            state.warehouses = value
        },
        SET_STORES(state, value) {
            state.stores = value
        },
        SET_PRICING(state, value) {
            state.pricing = value
        },
        SET_PREDICT(state, value) {
            state.predict = value
        },

    },
    actions: {
        setPricing({ commit }, pricing) {
            commit('SET_PRICING', pricing)
        },
        setPredict({ commit }, predict) {
            commit('SET_PREDICT', predict)
        },
        setStores({ commit }, stores) {
            commit('SET_STORES', stores)
        },
        setWarehouses({ commit }, warehouses) {
            commit('SET_WAREHOUSES', warehouses)
        },
        setStock({ commit }, stock) {
            commit('SET_STOCK', stock)
        },
        setSuppliers({ commit }, suppliers) {
            commit('SET_SUPPLIERS', suppliers)
        },
        setSales({ commit }, sales) {
            commit('SET_SALES', sales)
        },
        setProduct({ commit }, product) {
            commit('SET_PRODUCT', product)
        },
    },
    getters:{
        product: state => state.product,
        sales: state => state.sales,
        stock: state => state.stock,
        suppliers: state => state.suppliers,
        warehouses: state => state.warehouses,
        stores: state => state.stores,
        pricing: state => state.pricing,
        predict: state => state.predict
    }
}