// store/index.js

import Vue from "vue";
import Vuex from "vuex";
import { dashboard } from "./dashboard.module";
import { users } from "./users.module";
import { dataProcess } from "./dataProcess.module";
import { sessions } from "./sessions.module";
import { salesman } from "./salesman.module";
import { master_data } from "./master_data.module";
import { notifications } from "./notifications.module";
import { filtersData } from "./filters_data.module";
import { launch } from "./launch.module";
import { hanout } from "./hanout.module";


Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		sessions,
		users,
		dataProcess,
		dashboard,
		salesman,
		master_data,
		notifications,
		filtersData,
		launch,
		hanout
	},
});
export default store;
