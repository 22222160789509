import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
// import "@fortawesome/fontawesome-free/js/all.js";
import VCalendar from "v-calendar";
import router from "./_router";
import store from "./_store";
import "@/assets/css/main.scss";
import "@/assets/css/_variables.scss";

import axios from "axios";
import App from "./App.vue";
import i18n from "./i18n";
// import VueIntro from "vue-introjs";
// import "intro.js/introjs.css";

import HighchartsVue from "highcharts-vue";
import NProgress from "vue-nprogress";

Vue.use(NProgress);

// use HighchartsVue
Vue.use(HighchartsVue);

// use BootstrapVue
Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// vue-vis-network graph css
Vue.use(VCalendar);

// Vue.use(VueIntro);
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;

if (localStorage.getItem("token"))
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");
if (localStorage.getItem("lang"))
  axios.defaults.headers.common["Accept-Language"] = localStorage.getItem(
    "lang"
  );

Vue.prototype.$axios = axios;

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if ("response" in error) {
      if (error.response.status === 401) {
        store.dispatch("users/logout");
        router.push("/login");
      }
    }
    return Promise.reject(error);
  }
);
const currencies = {
  UADT: "€",
  YSE: "€",
  GM: "TND",
  PROPHOT: "€",
};
Vue.mixin({
  data: function() {
    return {
      get globalReadOnlyApiPort() {
        return process.env.VUE_APP_URL_BACK + "/api/";
      },
      get globalReadOnlyApiPortHanout() {
        return process.env.VUE_APP_URL_BACK_HANOUT + "/hanout";
      },
      get currencySymbol() {
        if (localStorage.getItem("company_name")) {
          return currencies[localStorage.getItem("company_name")];
        }
        return "€";
      },
    };
  },
});

const nprogress = new NProgress();

new Vue({
  router,
  store,
  i18n,
  nprogress,
  render: (h) => h(App),
}).$mount("#app");
