
export const launch = {
    namespaced: true,
    state: {
        globalBudget: "xxx.xxx",
        launch_budget: [],
        launch_products: [],
        launch_merch_camp: false,
    },
    mutations: {
        setGlobalBudget(state, globalBudget) {
            state.globalBudget = globalBudget
        },
        save_launch_budget(state, launch_budget) {
            state.launch_budget = launch_budget
        },
        save_launch_products(state, launch_products) {
            state.launch_products = launch_products
        },
        save_launch_merch_camp(state, launch_merch_camp) {
            state.launch_merch_camp = launch_merch_camp
        },

    },
    actions: {
        setGlobalBudget({ commit }, globalBudget) {
            commit('setGlobalBudget', globalBudget)
        },
        set_launch_budget({ commit }, launch_budget) {
            commit('save_launch_budget', launch_budget)
        },
        set_launch_products({ commit }, launch_products) {
            commit('save_launch_products', launch_products)
        },
        set_launch_merch_camp({ commit }, launch_merch_camp) {
            commit('save_launch_merch_camp', launch_merch_camp)
        },
    },
    getters: {
        globalBudget: state => state.globalBudget,
        launch_budget: state => state.launch_budget,
        launch_products: state => state.launch_products,
        launch_merch_camp: state => state.launch_merch_camp,

    }
}