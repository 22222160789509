export const salesman = {
	namespaced: true,
	state: {
		scenario_names: [],
		sales_reference: "Forecast",
		consensus_table: [],
		new_how_to: false,
		supply_save_id: localStorage.getItem("supply_save_id") || "",
		supply_checkpoint_id:
			localStorage.getItem("supply_checkpoint_id") || "",
		ok_consensus: false,
		consensus: 0,
		table_data_forecast: [],
		table_data_sales: [],
		forecast_impacts: {},
	},
	mutations: {
		save_scenario_names(state, scenario_names) {
			state.scenario_names = scenario_names;
		},
		save_sales_reference(state, sales_reference) {
			state.sales_reference = sales_reference;
		},
		save_consensus_table(state, consensus_table) {
			state.consensus_table = consensus_table;
		},
		new_how_to(state, new_how_to) {
			state.new_how_to = new_how_to;
		},
		save_supply_session_id(state, supply_save_id) {
			state.supply_save_id = supply_save_id;
			localStorage.setItem("supply_save_id", supply_save_id);
		},
		save_supply_checkpoint_id(state, chck) {
			state.supply_checkpoint_id = chck;
			localStorage.setItem("supply_checkpoint_id", chck);
		},
		set_ok_consensus(state, ok_consensus) {
			state.ok_consensus = ok_consensus;
		},
		set_consensus(state, consensus) {
			state.consensus = consensus * 0.01;
		},
		set_table_data_forecast(state, table_data_forecast) {
			state.table_data_forecast = table_data_forecast;
		},
		set_table_data_sales(state, table_data_sales) {
			state.table_data_sales = table_data_sales;
		},
		set_forecast_impacts(state, forecast_impacts) {
			state.forecast_impacts = forecast_impacts;
		},
	},
	actions: {
		set_scneario_names({ commit }, scenario_names) {
			commit("save_scenario_names", scenario_names);
		},
		set_sales_reference({ commit }, sales_reference) {
			commit("save_sales_reference", sales_reference);
		},
		set_consensus_table({ commit }, consensus_table) {
			commit("save_consensus_table", consensus_table);
		},
		new_how_to({ commit }, new_how_to) {
			commit("new_how_to", new_how_to);
		},
		set_supply_save_id({ commit }, save_id) {
			commit("save_supply_session_id", save_id);
		},
		set_supply_cp_id({ commit }, cp_id) {
			commit("save_supply_checkpoint_id", cp_id);
		},
		set_ok_consensus({ commit }, ok_consensus) {
			commit("set_ok_consensus", ok_consensus);
		},
		set_consensus({ commit }, consensus) {
			commit("set_consensus", consensus);
		},
		set_table_data_forecast({ commit }, table_data_forecast) {
			commit("set_table_data_forecast", table_data_forecast);
		},
		set_table_data_sales({ commit }, table_data_sales) {
			commit("set_table_data_sales", table_data_sales);
		},
		set_forecast_impacts({ commit }, forecast_impacts) {
			commit("set_forecast_impacts", forecast_impacts);
		},
	},
	getters: {
		scenario_names: (state) => state.scenario_names,
		sales_reference: (state) => state.sales_reference,
		consensus_table: (state) => state.consensus_table,
		new_how_to: (state) => state.new_how_to,
		supply_save_id: (state) => state.supply_save_id,
		supply_checkpoint_id: (state) => state.supply_checkpoint_id,
		ok_consensus: (state) => state.ok_consensus,
		consensus: (state) => state.consensus,
		table_data_forecast: (state) => state.table_data_forecast,
		table_data_sales: (state) => state.table_data_sales,
		forecast_impacts: (state) => state.forecast_impacts,
	},
};
