export const sessions = {
    namespaced: true,
    state: {
        save_id: localStorage.getItem('save_id') || '',
        checkpoint_id: localStorage.getItem('checkpoint_id') || '',
        session_name: localStorage.getItem('session_name' || ''),
        session_info: {},
        creation_date: localStorage.getItem('creation_date' || ''),
        session_gran: localStorage.getItem('session_gran' || ''),
        latest_session: null,
        loading_sm:false
    },
    mutations: {
        session_info(state, session) {
            state.session_info = session
        },
        session_gran(state, gran) {
            state.session_gran = gran
        },
        session_name(state, name) {
            state.session_name = name
        },
        save_session_id(state, save_id) {
            state.save_id = save_id
        },
        save_checkpoint_id(state, chck) {
            state.checkpoint_id = chck
        },
        save_creation_date(state, data) {
            state.creation_date = data
        },
        save_latest_session(state, data) {
            state.latest_session = data
        },

        save_loading_sm(state, data) {
            state.loading_sm = data
        },
    },
    actions: {
        set_session_info({ commit }, session) {
            commit('session_info', session)
        },
        set_session_gran({ commit }, name) {
            commit('session_gran', name)
            localStorage.setItem('session_gran', name)
        },
        set_session_name({ commit }, name) {
            commit('session_name', name)
            localStorage.setItem('session_name', name)
        },
        set_save_id({ commit }, save_id) {
            commit('save_session_id', save_id)
            localStorage.setItem('save_id', save_id)
        },
        set_cp_id({ commit }, cp_id) {
            commit('save_checkpoint_id', cp_id)
            localStorage.setItem('checkpoint_id', cp_id)
        },
        set_creation_date({ commit }, data) {
            commit('save_creation_date', data)
            localStorage.setItem('creation_date', data)
        },
        set_latest_session({ commit }, data) {
            commit('save_latest_session', data)
        },
        set_loading_sm({ commit }, data) {
            commit('save_loading_sm', data)
        },
    },
    getters: {
        save_id: state => state.save_id,
        checkpoint_id: state => state.checkpoint_id,
        session_name: state => state.session_name,
        session_info: state => state.session_info,
        session_gran: state => state.session_gran,
        creation_date: state => state.creation_date,
        latest_session: state => state.latest_session,
        loading_sm:state=> state.loading_sm
    }
}