export const dashboard = {
    namespaced: true,
    state: {
        filters_data: {},
        inputdata: JSON.parse(localStorage.getItem('inputdata')) || [],
        visualisation: JSON.parse(localStorage.getItem('visualisation')) || [],
        scenario_names: [],
        scenario_id: 0,
        reference_id: 1,
        specials: [],
        start_date: null,
        end_date: null,
        columns: [],
        scenarios_export: [],
        impacts: null,
        products: null,
        stores: null,
        clients: null,
        families: null,
        segments: null,
        areas: null,
        forecast_impacts: {},
        new_how_to: false,
        scope_data: {},
        scope_data_valuation: {},
        replenish_scope_data: [],
        launch_scope_data: [],
        warehouse_scope_data: [],
        eol_scope_data: [],
        price_scope_data: [],
        events_scope_data: [],
        scenarios_avg: [],
        all_data_scope_data: {},
        sku_designation_dict: JSON.parse(localStorage.getItem('sku_designation_dict')),
        graph_title: '',
        isDashboard: false,
        prod_scope_tags: {},
        stores_scope_tags: {},
        families_scope_tags: {},
        disp_budget: localStorage.getItem('disp_budget'),
        alloc_budget: localStorage.getItem('alloc_budget'),
        promo_camp: JSON.parse(localStorage.getItem('promo_camp')) || [],
        date_mur: "",
        currentAllocationDate : "",
        allocation_type : "",
        supplier_vision_selected_date : '',
        showEvent : {},
        hideEvent : {},

    },
    mutations: {
        SET_SHOW_EVENT(state, event) {
            state.showEvent = event
        },
        SET_HIDE_EVENT(state, event) {
            state.hideEvent = event
        },
        set_supplier_vision_selected_date(state, supplier_vision_selected_date) {
            state.supplier_vision_selected_date = supplier_vision_selected_date
        },
        set_allocation_type(state, allocation_type) {
            state.allocation_type = allocation_type
        },
        set_current_allocation_date(state, currentAllocationDate) {
            state.currentAllocationDate = currentAllocationDate
        },

        set_forecast_impacts(state, forecast_impacts) {
            state.forecast_impacts = forecast_impacts
        },

        specials(state, specials) {
            state.specials = specials
        },
        scenario_id(state, scenario_id) {
            state.scenario_id = scenario_id
        },
        reference_id(state, reference_id) {
            state.reference_id = reference_id
        },
        scenario_names(state, scenario_names) {
            state.scenario_names = scenario_names
        },
        inputdata(state, inputdata) {
            state.inputdata = inputdata
            localStorage.setItem('inputdata', JSON.stringify(inputdata))
        },
        visualisation(state, visualisation) {
            state.visualisation = visualisation
            localStorage.setItem('visualisation', JSON.stringify(visualisation))
        },

        filters_data(state, filters_data) {
            state.filters_data = filters_data
        },

        start_date(state, start_date) {
            state.start_date = start_date
        },
        end_date(state, end_date) {
            state.end_date = end_date
        },
        columns(state, columns) {
            state.columns = columns
        },
        scenarios_export(state, scenarios_export) {
            state.scenarios_export = scenarios_export
        },
        impacts(state, impacts) {
            state.impacts = impacts
        },
        products(state, products) {
            state.products = products
        },
        stores(state, stores) {
            state.stores = stores
        },
        clients(state, clients) {
            state.clients = clients
        },
        families(state, families) {
            state.families = families
        },
        segments(state, segments) {
            state.segments = segments
        },
        areas(state, areas) {
            state.areas = areas
        },
        new_how_to(state, new_how_to) {
            state.new_how_to = new_how_to
        },
        scope_data(state, scope_data) {
            state.scope_data = scope_data
        },
        scope_data_valuation(state, scope_data_valuation) {
            state.scope_data_valuation = scope_data_valuation
        },
        replenish_scope_data(state, resp) {
            state.replenish_scope_data = resp
        },
        launch_scope_data(state, resp) {
            state.launch_scope_data = resp
        },
        warehouse_scope_data(state, resp) {
            state.warehouse_scope_data = resp
        },
        eol_scope_data(state, resp) {
            state.eol_scope_data = resp
        },
        price_scope_data(state, resp) {
            state.price_scope_data = resp
        },
        events_scope_data(state, resp) {
            state.events_scope_data = resp
        },
        scenarios_avg(state, avg) {
            state.scenarios_avg = avg
        },
        all_data_scope_data(state, data) {
            state.all_data_scope_data = data
        },
        sku_designation_dict(state, data) {
            state.sku_designation_dict = data
            localStorage.setItem('sku_designation_dict', JSON.stringify(data))
        },
        graph_title(state, data) {
            state.graph_title = data
        },
        isDashboard(state, data) {
            state.isDashboard = data
        },
        save_prod_scope_tags(state, data) {
            state.prod_scope_tags = data
        },
        save_families_scope_tags(state, data) {
            state.families_scope_tags = data
        },
        save_stores_scope_tags(state, data) {
            state.stores_scope_tags = data
        },
        save_disp_budget(state, data) {
            state.disp_budget = data
            localStorage.setItem('disp_budget', data)
        },
        save_alloc_budget(state, data) {
            state.alloc_budget = data
            localStorage.setItem('alloc_budget', data)
        },
        promo_camp(state, data) {
            state.promo_camp = data
            localStorage.setItem('promo_camp', JSON.stringify(data))
        },
        date_mur(state, data) {
            state.date_mur = data
        },
    },
    actions: {
        set_show_event({ commit }, data) {
            commit('SET_SHOW_EVENT', data)
        },
        set_hide_event({ commit }, data) {
            commit('SET_HIDE_EVENT', data)
        },
        set_supplier_vision_selected_date({ commit }, data) {
            commit('set_supplier_vision_selected_date', data)
        },
        set_allocation_type({ commit }, data) {
            commit('set_allocation_type', data)
        },
        set_current_allocation_date({ commit }, data) {
            commit('set_current_allocation_date', data)
        },
        set_prod_scope_tags({ commit }, data) {
            commit('save_prod_scope_tags', data)
        },
        set_families_scope_tags({ commit }, data) {
            commit('save_families_scope_tags', data)
        },
        set_stores_scope_tags({ commit }, data) {
            commit('save_stores_scope_tags', data)
        },

        set_forecast_impacts({ commit }, forecast_impacts) {
            commit('set_forecast_impacts', forecast_impacts)
        },
        set_specials({ commit }, specials) {
            commit('specials', specials)
        },

        new_how_to({ commit }, new_how_to) {
            commit('new_how_to', new_how_to)
        },
        set_scenario_id({ commit }, scenario_id) {
            commit('scenario_id', scenario_id)
        },
        set_reference_id({ commit }, reference_id) {
            commit('reference_id', reference_id)
        },
        set_scenario_names({ commit }, scenario_names) {
            commit('scenario_names', scenario_names)
        },
        set_inputdata({ commit }, inputdata) {
            commit('inputdata', inputdata)
        },
        set_visualisation({ commit }, visualisation) {
            commit('visualisation', visualisation)
        },
        set_start_date({ commit }, start_date) {
            commit('start_date', start_date)
        },
        set_end_date({ commit }, end_date) {
            commit('end_date', end_date)
        },
        set_columns({ commit }, columns) {
            commit('columns', columns)
        },
        set_scenario_names_export({ commit }, scenarios_export) {
            commit('scenarios_export', scenarios_export)
        },
        set_impacts({ commit }, impacts) {
            commit('impacts', impacts)
        },
        set_stores({ commit }, stores) {
            commit('stores', stores)
        },
        set_areas({ commit }, areas) {
            commit('areas', areas)
        },
        set_filters_data({ commit }, filters_data) {
            commit('filters_data', filters_data)
        },
        set_scope_data({ commit }, scope_data) {
            commit('scope_data', scope_data)
        },
        set_scope_data_valuation({ commit }, scope_data_valuation) {
            commit('scope_data_valuation', scope_data_valuation)
        },
        set_replenish_scope_data({ commit }, scope_data) {
            commit('replenish_scope_data', scope_data)
        },
        set_launch_scope_data({ commit }, scope_data) {
            commit('launch_scope_data', scope_data)
        },
        set_warehouse_scope_data({ commit }, scope_data) {
            commit('warehouse_scope_data', scope_data)
        },
        set_eol_scope_data({ commit }, scope_data) {
            commit('eol_scope_data', scope_data)
        },
        set_price_scope_data({ commit }, scope_data) {
            commit('price_scope_data', scope_data)
        },
        set_events_scope_data({ commit }, scope_data) {
            for (let i = 0; i < scope_data.length; i++) {
                scope_data[i]['index'] = i
            }
            commit('events_scope_data', scope_data)
        },
        set_scenario_names_avg({ commit }, avg) {
            commit('scenarios_avg', avg)
        },
        set_all_data_scope_data({ commit }, data) {
            commit('all_data_scope_data', data)
        },
        set_sku_designation_dict({ commit }, data) {
            commit('sku_designation_dict', data)
        },
        set_graph_title({ commit }, data) {
            commit('graph_title', data)
        },
        set_isDashboard({ commit }, data) {
            commit('isDashboard', data)
        },
        set_disp_budget({ commit }, data) {
            commit("save_disp_budget", data)
        },
        set_alloc_budget({ commit }, data) {
            commit("save_alloc_budget", data)
        },
        set_promo_camp({ commit }, data) {
            commit("promo_camp", data)
        },
        set_date_mur({ commit }, data) {
            commit('date_mur', data)
        },

    },
    getters: {
        showEvent : state=>state.showEvent,
        hideEvent : state=>state.hideEvent,
        supplier_vision_selected_date : state=>state.supplier_vision_selected_date,
        allocation_type: state=> state.allocation_type,
        current_allocation_date : state=> state.currentAllocationDate,
        forecast_impacts: state => state.forecast_impacts,
        scenario_names: state => state.scenario_names,
        scenario_id: state => state.scenario_id,
        reference_id: state => state.reference_id,
        inputdata: state => state.inputdata,
        visualisation: state => state.visualisation,
        filters_data: state => state.filters_data,
        specials: state => state.specials,
        start_date: state => state.start_date,
        end_date: state => state.end_date,
        columns: state => state.columns,
        scenarios_export: state => state.scenarios_export,
        impacts: state => state.impacts,
        products: state => state.products,
        stores: state => state.stores,
        clients: state => state.clients,
        families: state => state.families,
        segments: state => state.segments,
        areas: state => state.areas,
        new_how_to: state => state.new_how_to,
        scope_data: state => state.scope_data,
        scope_data_valuation: state => state.scope_data_valuation,
        replenish_scope_data: state => state.replenish_scope_data,
        launch_scope_data: state => state.launch_scope_data,
        warehouse_scope_data: state => state.warehouse_scope_data,
        eol_scope_data: state => state.eol_scope_data,
        price_scope_data: state => state.price_scope_data,
        events_scope_data: state => state.events_scope_data,
        scenarios_avg: state => state.scenarios_avg,
        all_data_scope_data: state => state.all_data_scope_data,
        sku_designation_dict: state => state.sku_designation_dict,
        graph_title: state => state.graph_title,
        isDashboard: state => state.isDashboard,
        stores_scope_tags: state => state.stores_scope_tags,
        families_scope_tags: state => state.families_scope_tags,
        prod_scope_tags: state => state.prod_scope_tags,
        disp_budget: state => state.disp_budget,
        alloc_budget: state => state.alloc_budget,
        promo_camp: state => state.promo_camp,
        date_mur: state => state.date_mur,
    }
}